@use "../global-variables" as *;

.assessment.section {
  margin-top: 16px;
  background-color: $charcoal-05;
  border: none;
  p {
    font-size: 13px;
    margin-bottom: 0;
    color: $charcoal-100;
  }
  > .section-title {
    display: flex;
    justify-content: space-between;
    .assessment-name {
      font-size: 14px;
    }
    span {
      @include list-span;
      height: fit-content;
    }
    .days-remaining {
      color: $gold-130;
      background-color: $gold-10;
    }
    > div {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

.dashboard-blue-card.card,
.dashboard-banner {
  background-color: $sapphire-05;
  border: solid 1px $sapphire-20;
  border-radius: 4px;
}

.dashboard-banner {
  display: flex;
  align-items: center;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  p {
    color: $sapphire-150;
    margin-bottom: 0;
  }
}

.dashboard-banner-action-button {
  display: flex;
  gap: 8px;
}

.dashboard-blue-card.card {
  padding: 20px;
  flex-direction: column;
}

.dashboard-header-v2 {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dashboard-onboard-card-holder {
  position: relative;
  > .circle-number {
    position: absolute;
    z-index: 1;
    left: -16px;
    top: 16px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  }
}

.dashboard-onboard-card {
  padding: 20px;
  gap: 16px;
  p {
    margin-bottom: 0;
  }
}

.dashboard-onboard-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  > h2 {
    margin-bottom: 0;
  }
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  > .card {
    padding: 20px;
  }
}

.dashboard-container-header,
.card.dashboard-container-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.dashboard-container-header__info {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  p {
    color: $charcoal-100;
  }
}

.info-card-v2.card {
  padding: 20px;
  text-align: left;
  gap: 20px;
  button {
    text-align: left;
  }
}
.info-card-v2__title {
  font-size: 14px;
  font-weight: 700;
  color: $charcoal-100;
}
.info-card-v2__pending-text {
  color: $sapphire-150;
}

.three-card-container,
.two-card-container {
  display: grid;
  gap: 24px;
}

.three-card-container {
  grid-template-columns: repeat(3, 1fr);
  .quick-action-card {
    height: 100%;
  }
}

.two-card-container {
  grid-template-columns: repeat(2, 1fr);
}

.my-team-360-card {
  display: flex;
  border: 1px solid $sapphire-20;
  border-radius: 4px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
}

.my-team-360-card-action-info,
.my-team-360-card-action {
  display: flex;
  align-items: center;
  gap: 12px;
}
.my-team-360-card-action {
  gap: 8px;
  > .small-body-text {
    color: $charcoal-70;
  }
}

.my-team-360-card-info-text {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 220px;
  > h3 {
    font-size: 14px;
    color: black;
  }
  > .small-body-text {
    color: $charcoal-100;
    margin-bottom: 0;
  }
  > p {
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.results-ready-list-span {
  @include list-span;
  font-size: 12px;
  background-color: $indicator-success-green-20;
  color: $indicator-success-green-120;
}

.dashboard-onboard-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .dashboard-onboard-card-footer-buttons {
    display: flex;
    gap: 8px;
  }
  > .dashboard-onboard-card-footer-text {
    color: $charcoal-100;
    font-size: 14px;
  }
}

.dashboard-header-dropdowns {
  display: flex;
  gap: 20px;
}

.dashboard-body-v2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  > .dashboard-body-v2-column {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  > .dashboard-info-cards {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
}

@media (max-width: 992px) {
  .dashboard-body-v2 {
    grid-template-columns: 100%;
    margin-top: 24px;
  }
}

.four-team-member-holder {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.circle-number {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  width: 44px;
  font-size: 16px;
  font-weight: 700;
  &.pending {
    background-color: $charcoal-100;
    color: white;
  }
  &.completed {
    background-color: $indicator-success-green-20;
    color: $indicator-success-green-120;
  }
  &.active {
    background-color: $sapphire-100;
    color: white;
    animation: pulsate 2s infinite;
  }

  &::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 1px solid white;
    background: transparent;
  }

  &.medium {
    height: 32px;
    width: 32px;
    &::before {
      width: 28px;
      height: 28px;
    }
  }
  &.large {
    height: 60px;
    width: 60px;
    &::before {
      width: 50px;
      height: 50px;
    }
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.sent-to {
  display: flex;
  align-items: center;
  margin-top: 16px;
  > .avatar-circle {
    margin-right: -3px;
    &:last-of-type {
      margin-right: 8px;
    }
  }
}

span.days-remaining {
  color: $gold-130;
  background-color: $gold-10;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  padding: 3px 4px;
}

.reminder.section {
  > .team-info,
  > .posted-by {
    display: flex;
  }
  > .team-info {
    gap: 8px;
  }
  > .posted-by {
    gap: 4.5px;
  }
  > .section-footer-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    > button {
      font-weight: bold;
    }
  }
  > .section-header {
    > .title {
      display: flex;
      gap: 8px;
      > .recommended {
        @include list-span;
      }
    }
  }
}
button.dismiss {
  border: none;
  color: $charcoal-100;
}

.reminder-cards {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

@media (min-width: 1200px) {
  .page.dashboard {
    grid-template-columns: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page.dashboard {
    grid-template-columns: 960px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page.dashboard {
    grid-template-columns: 720px;
  }
  .dashboard-container .three-card-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .page.dashboard {
    grid-template-columns: 540px;
  }
  .dashboard-container .three-card-container {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 575px) {
  .page.dashboard {
    grid-template-columns: 100%;
  }
  .dashboard-container .three-card-container {
    grid-template-columns: 1fr;
  }
}

.user-welcome-card {
  > .profile-complete {
    background-color: #fff5e5;
    display: flex;
    border-radius: 8px;
    padding: 8px;
    margin: 3px;
    width: 350px;
  }
  > .user-info {
    display: flex;
    gap: 16px;
    margin: 16px 0;
    align-items: center;
  }
}

.dashboard-department-leader-onboarding-card.card {
  grid-column: 2 span;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 2fr;
}
