@use '../global-variables' as *;

.toggle {
  &.active {
    background-color: $sapphire-100;
  }
  display: inline-block;
  background-color: grey;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease-in-out;
  // Small
  width: 32px;
  height: 16px;
  border-radius: 16px;

  // Medium
  &.md {
    width: 48px;
    height: 24px;
    border-radius: 24px;
    .toggle-thumb {
      top: 2px;
      left: 1.75px;
      width: 20px;
      height: 20px;
      border-radius: 20px;
    }
    &.active .toggle-thumb {
      transform: translateX(24px);
    }
  }
}

.toggle-thumb {
  transition: transform 0.3s ease-in-out;
  background-color: #fff;
  position: absolute;
  // Small Settings
  top: 1px;
  left: 1px;
  width: 14px;
  height: 14px;
  border-radius: 14px;
}

.toggle.active .toggle-thumb {
  // Small Settings
  transform: translateX(16px);
}
