@use "../global-variables" as *;

.factor-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
}

.navigation-box {
  display: flex;
  flex-direction: column;
  height: fit-content;
  > div {
    border: solid 1px $charcoal-20;
    padding: 24px;
    cursor: pointer;
    &.selected {
      background-color: #f5f7fc;
      border: 2px solid #425cc7 !important;
    }
    > span {
      font-size: 16px;
      font-weight: bold;
      color: $navy-100;
    }
    &.active {
      background-color: $sapphire-05;
      border: solid 2px $sapphire-100;
    }
  }
}

.skills-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-top: 12px;
  > .card {
    display: flex;
    padding: 16px;
    align-items: flex-start;
    > button {
      margin-top: auto;
      width: fit-content;
    }
    > h4 {
      margin-bottom: 4px;
    }
    > p {
      margin-bottom: 12px;
      color: $charcoal-100;
    }
  }
}

hr.selected {
  border: solid 1px $sapphire-100;
  opacity: 1;
}

.factor-card-small.card {
  padding: 8px;
  transition-property: border, background-color;
  transition-duration: 0.3s;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  h4,
  p {
    margin-bottom: 0;
  }
  p {
    color: $charcoal-100;
  }
  &:hover,
  &.selected {
    background-color: $sapphire-05;
    border: solid 1px $sapphire-100;
  }
}

.skill-focus-card {
  padding: 20px;
  > span {
    color: $charcoal-100;
  }
  > p {
    color: black;
    font-size: 14px;
    font-weight: bold;
  }
}

.skill-focus-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.skill-focus-card__footer {
  background-color: $sapphire-05;
  color: $sapphire-150;
  padding: 12px 16px;
  display: flex;
  align-items: center;
}

.skill-focus-card__footer-text {
  > div {
    @include line-clamp(1);
    > span {
      font-weight: bold;
    }
  }
}

.skills-guide-accordion-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: space-between;
    > span {
      color: $charcoal-100;
      font-size: 14px;
      > svg {
        color: $sapphire-100;
      }
    }
  }
  > h3 {
    margin-bottom: 0;
  }
}

.skill-guide-section-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  margin-top: 12px;
  > div {
    display: flex;
    gap: 8px;
    align-items: center;
    > h3 {
      color: black;
      margin-bottom: 0;
    }
  }
}

.medium-icon-square,
.small-icon-square {
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $sapphire-05;
  color: $sapphire-150;
  aspect-ratio: 1;
  &.grey {
    background-color: $charcoal-10;
    color: $charcoal-100;
  }
  &.dark-grey {
    background-color: $charcoal-100;
    color: white;
  }
  &.blue {
    background-color: $sapphire-100;
    color: white;
  }
  &.blue {
    background-color: $sapphire-100;
    color: white;
  }
  &.ruby {
    background-color: $ruby-10;
    color: $ruby-100;
  }
  &.cyan {
    background-color: $cyan-10;
  }
}

.small-icon-square {
  width: 44px;
  height: 44px;
  font-size: 20px;
}

.medium-icon-square {
  width: 80px;
  height: 80px;
  font-size: 32px;
}

.skills-page {
  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    > .thumb-buttons {
      display: flex;
      gap: 8px;
    }
  }
  > .skills-page-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 16px;
    margin-top: 12px;
    > .nav-content {
      padding: 0 12px;
    }
  }
}

.skill-page-header {
  display: flex;
  gap: 12px;
  align-items: center;
  margin: 8px 0;
}

.edit-skill-content-modal-body {
  textarea {
    height: 142px;
    margin-bottom: 12px;
  }
}

.list-span {
  &.Skill {
    color: $sky-130;
    background-color: $sky-10;
  }
  &.Dimension {
    color: $poppy-130;
    background-color: $poppy-10;
  }
}

.skill-search-option {
  margin: 0;
  > span {
    color: $navy-100;
    font-size: 14px;
  }
  > p {
    margin: 0;
    color: $charcoal-100;
  }
}

.factor-intro {
  background-color: $sapphire-05;
  padding: 20px;
  margin: 12px 0;
  > p {
    color: $charcoal-100;
  }
}
