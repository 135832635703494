@use '../global-variables' as *;

.user-bubble {
  border-radius: 20px 20px 2px 20px;
  padding: 20px;
  margin: 5px;
  background: #e3e9ff;
  width: fit-content;
  align-self: flex-end;
}

.assistant-avatar {
    height: 40px;
    margin-right: 10px;
}
.assistant-bubble-row {
    display: flex;
    align-items: flex-end;
}

.assistant-bubble {
  border-radius: 20px 20px 20px 2px;
  padding: 20px;
  margin: 5px;
  background: #f7f7f7;
  width: 100%;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.chatbot-prompt {
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: #D9DEF4 1px solid;
    padding: 12px;
    background-color: white;
    width: 100%;
    color: #202D63;
    font-weight: bold;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.chatbot-prompt-text {
    width: 80%;
    text-align: left;
}

.chatbot-prompt:hover {
    background: #F7F7F7;
}

.chatbot-prompt-container {
    border-top: #D9DEF4 1px solid;
    border-right: #D9DEF4 1px solid;
    border-left: #D9DEF4 1px solid;
    border-radius: 4px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.chatbot-input {
    border-radius: 4px;
    padding: 8px 8px 8px 12px;
    height: 48px;
    border: solid 1px #a9aaac;
    background-color: #fff;
    margin: 10px;
}

.chatbot-card-full-screen {
    bottom: 20px;
    right: 10vh;
    width: 90vw;
    z-index: 1001;
}

.chatbot-card {
    bottom: 20px;
    right: 20px;
    width: 400px;
    z-index: 1001;
}

.chatbot-fullscreen-toggle {
    margin-left: 10px;
    margin-bottom: 3px;
    color: #425CC7;
    cursor: pointer;
}

.chatbot-fullscreen-toggle:hover {
    color: #202D63;
}

.chatbot-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.chatbot-message-container {
    margin-top: 10px;
    overflow-y: scroll;
    display: flex;
    padding: 10px;    
}

.inner-chatbot-message-container {
    display: flex;
    flex-direction: column-reverse;
}

.chatbot-links {
    margin-left: 10px;
    margin-bottom: 5px;
    color: #53565A;
    a {
        color: #425CC7;
    }
}

.chatbot-x-mark {
    color: #425CC7;
    cursor: pointer;
}

.chatbot-x-mark:hover {
    color: #202D63;
}

.floating-chatbot-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 98;
    border-radius: 40px;
    background: linear-gradient(223deg, #E3E9FF -24.96%, #FFF 123.08%);
    padding: 8px 16px;
    border: none;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    color: #202D63;
    font-weight: 500;
    cursor: pointer;
}

.typing-dots .dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background: gray;
    border-radius: 50%;
    animation: dot 1.2s infinite;
  }
  
  .typing-dots .dot:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-dots .dot:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dot {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }