@use '../global-variables' as *;

.survey-card {
  padding: 20px;
  background: $linear-gradient-grey;
  display: flex;
  flex-direction: column;
  gap: 20px;
  > .section-header {
    margin-bottom: 0;
    .title {
      display: flex;
      gap: 8px;
    }
  }
}

.survey-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  p {
    font-size: 13px;
    margin-bottom: 0;
  }
}

.survey-card-posted-by {
  display: flex;
  align-items: center;
  gap: 12px;
}

.multi-avatar-container {
  display: flex;
  align-items: center;
  > .avatar-circle {
    margin-right: -3px;
    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.custom-message-container {
  display: flex;
  justify-content: space-between;
  > .custom-message {
    max-width: 90%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    > .label {
      font-size: 12px;
      color: $charcoal-100;
    }
  }
  p {
    font-size: 14px;
    color: black;
  }
}
