@use "../global-variables" as *;

.sortable-table {
  width: 100%;
  > thead > tr {
    border-bottom: 2px #d8d9d9 solid;
    > th {
      padding-top: 18px;
      padding-bottom: 18px;
      white-space: nowrap;
    }
  }
  > tbody > tr {
    border-bottom: 1px #d8d9d9 solid;
  }
  td,
  th {
    padding: 18px 0 18px 12px;
    max-width: 280px;
  }
  .clickable {
    cursor: pointer;
    transition: background-color 0.3s ease; /* Adjust the duration and timing function as needed */
    &:hover {
      background-color: $charcoal-10;
    }
  }
}

.admin-console-table {
  table-layout: fixed;
  th {
    width: 160px;
  }
  td,
  th {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 280px;
  }
  tr > td:last-child {
    overflow-x: visible;
    .dropdown {
      position: unset;
    }
  }
  tr.deactivated {
    color: $charcoal-80;
  }
}

.invitee-cell {
  display: flex;
  align-items: center;
  gap: 10px;
  > p,
  > a {
    max-width: 160px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}

.simple-pagination {
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
  .page-item {
    &.selected {
      button {
        background-color: $sapphire-100 !important;
        color: #fff !important;
      }
    }
  }
}

th.table-dropdown-header {
  width: 30px;
}
