@use '../global-variables' as *;

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  > .card {
    padding: 20px;
    width: 640px;
    gap: 20px;
    .login-card-info-text {
      font-size: 18px;
      color: $charcoal-100;
    }
    > .company-logo {
      height: 64px;
      align-self: baseline;
    }
  }
}

@media screen and (max-width: 640px) {
  .login-page {
    > .card {
      width: 100%;
    }
  }
}
