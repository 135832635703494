@use "../global-variables" as *;

.assessment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    width: auto;
    display: inline;
  }
  svg {
    color: $sapphire-100;
  }
}

.team-360-footer {
  display: flex;
  justify-content: space-between;
}

.assessment-progress-bar {
  height: 12px;
  width: 100%;
  background-color: $charcoal-10;
  border-radius: 12px;
  overflow: hidden;
  > .fill {
    border-radius: 12px;
    height: 100%;
    width: 0%;
    transition: width 0.5s;
    background-color: $sapphire-150;
  }
}

.progress-bar-container {
  > .progress-bar-greeting {
    color: $charcoal-100;
    font-size: 14px;
    font-weight: bold;
  }
  > .progress-bar-description {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: bold;
    > .next {
      color: $charcoal-100;
    }
    > .current {
      font-weight: bold;
    }
  }
}

.team-360-section-description-icon {
  background-color: $sapphire-150;
  color: white;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  margin-top: -62px;
  border-radius: 8px;
}

.team-360-section-description-title {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
  margin-bottom: 8px;
  > span {
    background-color: #202d63;
    color: white;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;
  }
  > h1 {
    margin-bottom: 0;
  }
}

.team-360-section-description {
  padding: 20px;
  margin-top: 42px;
  background: $linear-gradient-grey;
  display: flex;
  flex-direction: column;
  gap: 20px;
  > h1 {
    padding-top: 20px;
    padding-bottom: 16px;
  }
  p {
    font-size: 13px;
    margin: 0;
    color: $charcoal-100;
    margin: 0;
  }

  li {
    font-size: 16px;
  }
  ul {
    padding-left: 20px;
  }
  > p {
    margin-bottom: 0;
  }
  > .team-360-icon {
    margin-top: -60px;
    height: 80px;
    width: 80px;
  }
}

.time-and-question-count {
  display: flex;
  gap: 20px;
  border-radius: 4px;
  color: $charcoal-100;
  font-weight: bold;
  > span {
    display: flex;
    align-items: center;
    gap: 8px;
    > div {
      background-color: $charcoal-05;
      color: $charcoal-100;
      font-weight: bold;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      height: 40px;
      width: 40px;
    }
  }
  @media (max-width: 576px) {
    flex-direction: column;
    > span {
      margin-bottom: 12px;
    }
  }
}

// TEAMscan Results Styles //
.team360-factor-holder {
  display: grid;
  gap: 12px;
}

.team360-results-card-overview {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 20px;
  &.single {
    > .div {
      flex-grow: 2;
    }
  }
}
.team360-results-card-overview-chart {
  padding: 24px 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.single {
    flex-grow: 2;
  }
}

.team-360-overview-list {
  padding-left: 0;
  > li {
    list-style: none;
    display: flex;
    gap: 12px;
    > svg {
      margin-top: 5px;
    }
  }
}

.team360-results-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .icon-holder {
  }
  &.card {
    height: fit-content;
  }
}

.assessment-icon-holder {
  background-color: $sapphire-10;
  color: $sapphire-130;
  font-weight: bold;
  border-radius: 4px;
  white-space: nowrap;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.factor-name-label {
  font-size: 14px;
  color: $charcoal-100;
  font-weight: bold;
  margin-bottom: 6px;
  display: block;
}

.team-360-accordion {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 12px;
  padding-bottom: 4px;
}

.team-360-accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.team-360-accordion-container {
  border-bottom: 1px solid $sapphire-20;
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  &:last-of-type {
    border-bottom: none;
  }
}
.snapshot-box {
  display: flex;
  gap: 8px;
  padding: 16px;
  border-radius: 4px;
  background-color: $sapphire-05;
  color: $sapphire-150;

  > p {
    margin-bottom: 0;
  }
  > svg {
    margin-top: 4px;
  }
}

.short-graph {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.short-graph-bar {
  display: flex;
  position: relative;
  justify-content: space-between;
  height: 28px;
  font-size: 14px;
  align-items: center;
  > span {
    z-index: 1;
  }
  > .bar {
    z-index: 0;
    position: absolute;
    height: 100%;
    transition: width 0.5s;
    border-radius: 8px;
  }
  &.my-score {
    font-weight: bold;
  }
  &.my-score > .bar {
    background-color: $sapphire-10;
  }
  &.your-score > .bar {
    background-color: $charcoal-10;
  }
}

.team360-results-card-overview-text {
  z-index: 1000;
  font-size: 14px;
  color: $sapphire-150;
  margin-bottom: 0;
}

.verbal-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 12px;
  &.super {
    background-color: $sapphire-20;
    color: $sapphire-100;
  }
  &.high {
    background-color: $indicator-success-green-20;
    color: $indicator-success-green-120;
  }
  &.medium {
    background-color: $gold-10;
    color: $gold-130;
  }
  &.low {
    background-color: $poppy-10;
    color: $poppy-130;
  }
  &.lowest {
    background-color: $indicator-error-red-10;
    color: $indicator-error-red-120;
  }
  &.no-results {
    color: $charcoal-100;
    background-color: $charcoal-10;
  }
  &.bigger {
    font-size: 14px;
  }
}

.status-dot {
  border-radius: 50%;
  border: solid 1.5px;
  height: 12px;
  width: 12px;
  &.blue {
    background-color: $sapphire-20;
    border-color: $sapphire-100;
  }
  &.green {
    background-color: $indicator-success-green-20;
    border-color: $indicator-success-green-120;
  }
  &.yellow {
    background-color: $indicator-alert-yellow-20;
    border-color: $gold-100;
  }
  &.poppy {
    background-color: $poppy-10;
    border-color: $poppy-100;
  }
  &.red {
    background-color: $indicator-error-red-10;
    border-color: $indicator-error-red-100;
  }
}

.top-and-bottom-factors {
  width: 100%;
}
.top-and-bottom-factors-label {
  font-size: 14px;
  color: $charcoal-100;
}
.top-and-bottom-factors-button {
  display: flex;
  gap: 12px;
  align-items: center;
}

.feedback-stars-container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-360-score-breakdown-tooltip {
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    > span {
      width: fit-content;
      white-space: nowrap;
    }
  }
}

.assessment-container {
  max-width: 720px;
  width: 100%;
  margin-top: 64px;
  > .card {
    padding: 20px;
  }
  @media (max-width: 720px) {
    max-width: 680px;
  }
}

.team-360-icon {
  &.sm {
    width: 16px;
    height: 16px;
  }
  &.md {
    width: 40px;
    height: 40px;
  }
  &.lg {
    width: 60px;
    height: 60px;
  }
  &.xl {
    width: 80px;
    height: 80px;
  }
  &.white path {
    stroke: white;
  }
  &.black path {
    stroke: $charcoal-100;
  }
  &.navy path {
    stroke: $sapphire-150;
  }
}

.team-360-full-page-card {
  padding: 20px;
  gap: 20px;
  > .card {
    padding: 20px;
  }
  > h2 {
    margin-bottom: 0;
  }
}

.team-360-full-page-card__section {
  > .card {
    padding: 20px;
  }
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.team-360-divider-title {
  color: $charcoal-100;
}

.team-360-full-page-card-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > * {
    flex: 1;
  }
}

.team-360-completion-rate {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-right: solid 1px $sapphire-20;
    border-left: solid 1px $sapphire-20;
    &:first-of-type {
      border-left: none;
    }
    &:last-of-type {
      border-right: none;
    }
    > span.count {
      font-size: 16px;
      color: black;
      font-weight: bold;
    }
    > span.label {
      font-size: 14px;
      color: $charcoal-100;
    }
  }
}

.launch-survey-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.launch-surveys__preview-text {
  margin-top: -12px;
}

.assessment-copy-right-text {
  margin-top: 32px;
  text-align: center;
  color: $charcoal-100;
  > a {
    color: $charcoal-100;
  }
}

.team-360-section-in-survey {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
}

.small-team360-preview-card.card {
  display: flex;
  flex-direction: row;
  // justify-content: space-between;
  .half-donut-chart-wrapper {
    margin-top: -20px;
  }
}

.dropdown-smaller-text {
  font-size: 12px;
  color: $charcoal-100;
}

.dropdown-bigger-text {
  font-size: 14px;
  color: $navy-100;
}

.dropdown-smaller-text,
.dropdown-bigger-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-survey-card-content {
  gap: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-survey-card-content__text {
  max-width: 470px;
  text-align: center;
  margin-bottom: 20px;
}

.smiley-feedback-icon.inactive {
  filter: grayscale(100%);
  cursor: pointer;
  &:hover {
    filter: grayscale(0%);
  }
}
