@use '../global-variables' as *;

.dropdown-menu.show {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  padding: 0;
  .dropdown-divider {
    margin: 0;
  }
  .danger {
    color: $indicator-error-red-100;
  }
  a {
    padding: 16px;
  }
  .dropdown-label {
    display: block;
    font-size: 12px;
    color: $charcoal-100;
    padding: 16px;
    padding-bottom: 8px;
  }
  .dropdown-selected {
    background-color: $sapphire-10;
    border: 1px solid $sapphire-20;
  }
}

.dropdown-menu-240px {
  width: 240px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    top: 44%;
    right: 16px;
    position: absolute;
  }
}
