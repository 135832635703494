@use "../global-variables" as *;

.admin-banner {
  display: flex;
  background-color: $sapphire-05;
  p {
    color: $sapphire-150;
  }
  padding: 8px 20px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
}

// Hidden Admin console styles
.admin-console-table-header {
  font-weight: 700;
  min-width: 116px;
}

.admin-console-table-header.warning {
  background: $yellow-20;
}

.admin-console-table-card {
  .preview-toggle {
    color: $sapphire-150;
    display: inline;
    font-weight: 400;
  }
}

.admin-console-modal {
  .upload-status-card {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.page.admin-console {
  > .card {
    padding: 20px;
  }
}

@media (min-width: 1200px) {
  .page.admin-console {
    grid-template-columns: 1140px;
  }
  .analytics-sticky-footer {
    width: 1140px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .page.admin-console {
    grid-template-columns: 960px;
  }
  .analytics-sticky-footer {
    width: 960px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .page.admin-console {
    grid-template-columns: 720px;
  }
  .analytics-sticky-footer {
    width: 720px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .page.admin-console {
    grid-template-columns: 540px;
  }
  .analytics-sticky-footer {
    width: 540px;
  }
}

.card {
  > .analytics {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.analytics-description-boxes {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
}

.survey-config-form {
  flex-direction: column;
  display: flex;
  gap: 8px;
}

.analytics-summary-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  p {
    margin-bottom: 0;
  }
  > .count {
    font-weight: bold;
    margin-bottom: 8px;
  }
  .low-and-high-summary-section {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    gap: 20px;
    padding-top: 20px;
    > .vertical-line {
      background-color: $sapphire-20;
    }
    > div > p {
      color: $charcoal-100;
    }
    .snapshot-box {
      flex-grow: 1;
    }
  }
}

.analytics-variance-legend {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  > div {
    display: flex;
    align-items: center;
    gap: 6px;
  }
}

svg.top {
  color: $sapphire-100;
}
svg.median {
  color: $cyan-100;
}
svg.bottom {
  color: $ruby-100;
}

.small-user-card {
  display: flex;
  align-items: center;
  gap: 12px;
  background-color: $charcoal-05;
  padding: 12px 16px;
  width: 100%;
  > .small-user-card-text {
    > h4 {
      font-size: 14px;
      margin-bottom: 0;
    }
    > span {
      font-size: 12px;
    }
  }
}

.analytics-sticky-footer {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  justify-content: space-between;
  mask: linear-gradient(transparent, black, black);
  backdrop-filter: blur(10px);
  background: linear-gradient(transparent, $charcoal-05);
  z-index: 999;
  padding: 24px 20px;
  padding-top: 80px;
  align-items: center;
  > div {
    display: flex;
    gap: 12px;
  }
}

.analytics-filter-drawer-body {
  .checkbox-group {
    padding: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
  input[type="checkbox"] {
    margin-right: 8px;
  }
}

.filter-span {
  @include list-span;
  &.active,
  &:hover {
    color: white;
    background-color: $sapphire-100;
  }
}

.free-text-insights-modal {
  .collapsible-card {
    background-color: $sapphire-05;
    border-radius: 4px;
    padding: 12px 16px;
  }
  .collapsible-cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .user-feedback {
    display: flex;
    gap: 12px;
  }
  .author {
    color: $charcoal-100;
  }
  .quote {
    color: black;
    margin-bottom: 8px;
  }
}

.anonymous-user {
  height: 32px;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $sapphire-20;
}

.deactivate-user-modal-body {
  text-align: center;
  > span {
    font-weight: bold;
    font-size: 16px;
  }
  > p {
    font-size: 14px;
  }
}
.deactivate-user-modal-footer {
  display: flex;
  justify-content: center;
  gap: 12px;
  align-items: center;
  margin-top: 12px;
}

.updated-teams {
  padding: 16px;
  background: $sapphire-05;
  border-radius: 4px;
  > ul {
    margin-bottom: 0;
    > li {
      list-style: none;
    }
  }
}

.department-name-tag {
  color: $sapphire-150;
  background-color: $sapphire-05;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  width: fit-content;
}

.team-reflection-div {
  display: flex;
  align-items: center;
  gap: 24px;
  > .team-reflection-info {
    gap: 8px;
  }
}

.label-tag {
  padding: 4px 8px;
  border-radius: 4px;
  margin: 5px;
  font-weight: bold;
  font-size: 12px;
  width: fit-content;
  height: fit-content;
  &.lg {
    font-size: 14px;
  }
  &.green {
    color: $indicator-success-green-120;
    background-color: $indicator-success-green-20;
  }

  &.blue {
    color: $sapphire-100;
    background-color: #d9e1ff;
  }

  &.yellow {
    color: $gold-130;
    background-color: $yellow-20;
  }

  &.orange {
    color: $poppy-130;
    background-color: $poppy-10;
  }

  &.red {
    color: $indicator-error-red-120;
    background-color: $indicator-error-red-10;
  }

  &.grey {
    color: $charcoal-100;
    background-color: $charcoal-10;
  }
  &.bigger {
    font-size: 14px;
  }
}
