@use "../global-variables" as *;

.side-drawer.card {
  margin-top: -20px;
  position: fixed;
  padding: 20px;
  z-index: 99;
  height: calc(100vh - 158px);
  overflow-y: scroll;
  transition: right 0.5s ease-out;
  right: -800px;

  &.active {
    right: 0;
  }
}

.side-drawer.quick-actions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 400px;
  height: -webkit-fill-available;
  margin-bottom: 20px;
}

.quick-action-card.card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  align-items: center;
  text-align: center;
  &.disabled {
    opacity: 0.5;
  }
}

.quick-action-card-corner-tag {
  z-index: 1;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}
