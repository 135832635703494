// Unknowns
$unknown-1: #032468;

$dark-blue-2: #001f4e;
$dark-blue-4: #2e408a; /* Sapphire/Sapphire 150 */
$blue-2: #546ccc;
$light-blue-1: #eceef9;

$yellow-20: #fff7cc; /* Yellow 20 */

$grey-1: #86888b;

$navy-80: #334c71;
$navy-100: #001f4e;
$navy-90: #19355f;
$navy-40: #99a5b8;
$navy-70: #4c6283;
$navy-30: #b2bbc9;
$navy-20: #ccd2dc;
$navy-60: #667995;
$navy-50: #7f8fa6;
$navy-05: rgba(0, 31, 78, 0.05);
$navy-10: #e5e8ed;
$gold-100: #ffa300;
$gold-90: #ffac19;
$gold-80: #ffb533;
$gold-70: #ffbe4c;
$gold-50: #ffd17f;
$gold-60: #ffc866;
$gold-40: #ffda99;
$gold-30: #ffe3b2;
$gold-20: #ffedcc;
$gold-10: #fff5e5;
$gold-130: #b27100;
$gold-150: #805100;
$cyan-100: #2dccd3;
$cyan-90: #42d1d7;
$cyan-80: #57d6dc;
$cyan-70: #6cdbe0;
$cyan-50: #96e5e9;
$cyan-60: #81e0e5;
$cyan-40: #abebed;
$cyan-30: #c0eff1;
$cyan-10: #eaf9fa;
$cyan-20: #d5f5f6;
$cyan-150: #166669;
$cyan-130: #1f8e93;
$interactive-color: #425cc7;
$sapphire-100: #425cc7;
$sapphire-90: #546ccc;
$sapphire-70: #7a8cd7;
$sapphire-80: #687dd2;
$sapphire-50: #a0ade3;
$sapphire-60: #8e9ddd;
$sapphire-40: #b3bee9;
$sapphire-30: #c6ceee;
$sapphire-20: #d9def4;
$sapphire-10: #eceef9;
$sapphire-05: #f5f7fc;
$sapphire-130: #2e408a;
$sapphire-150: #202d63;
$poppy-100: #ff5c39;
$poppy-80: #ff7d61;
$poppy-90: #ff6c4c;
$poppy-60: #ff9d88;
$poppy-70: #ff8c74;
$poppy-50: #ffad9c;
$poppy-40: #ffbeb0;
$poppy-20: #ffded7;
$poppy-30: #ffcec3;
$poppy-130: #b34028;
$poppy-10: #ffeeeb;
$ruby-100: #d50057;
$poppy-150: #7f2d1c;
$ruby-80: #dd3379;
$ruby-90: #d91967;
$ruby-60: #e6669a;
$ruby-70: #e14c89;
$ruby-40: #ee99bc;
$ruby-50: #ea7fab;
$ruby-30: #f2b2cc;
$ruby-20: #f7ccdd;
$ruby-10: #fae5ee;
$ruby-130: #94003c;
$ruby-150: #6a002b;
$sky-100: #00a9e0;
$sky-90: #19b1e3;
$sky-80: #33bae6;
$sky-70: #4cc2e9;
$sky-60: #66cbec;
$sky-50: #7fd4ef;
$sky-40: #99ddf3;
$sky-30: #b2e5f5;
$sky-20: #cceef9;
$sky-10: #e5f6fb;
$sky-130: #00759c;
$sky-150: #005470;
$charcoal-100: #53565a;
$charcoal-90: #64666a;
$charcoal-80: #75787b;
$charcoal-70: #86888b;
$charcoal-60: #989a9c;
$charcoal-50: #a9aaac;
$charcoal-40: #babbbd;
$charcoal-30: #cbcccd;
$charcoal-20: #ddddde;
$charcoal-10: #edeeee;
$charcoal-05: #f7f7f7;

$neutral-black-50: rgba(0, 0, 0, 0.5);
$neutral-black-100: #000;
$neutral-white-100: #fff;
$neutral-white-50: rgba(255, 255, 255, 0.5);
$neutral-white-30: rgba(255, 255, 255, 0.3);
$indicator-error-red-100: #d50057;
$neutral-white-20: rgba(255, 255, 255, 0.2);
$indicator-error-red-10: #fae5ee;
$neutral-white-0: #fff;
$indicator-error-red-120: #aa0045;
$neutral-white-10: rgba(255, 255, 255, 0.1);
$indicator-success-green-100: #00c067;
$indicator-alert-yellow-100: #ffd600;
$indicator-success-green-120: #009952;
$indicator-success-green-20: #ccf2e1;
$indicator-alert-yellow-20: #fff7cc;
$indicator-alert-yellow-120: #ccab00;
$indicator-redlines: #e8178a;
$linear-gradient-grey: linear-gradient(
  225deg,
  rgba(242, 244, 247, 0.3) 0%,
  rgba(236, 238, 242, 0.3) 20%,
  rgba(219, 222, 230, 0.3) 46%,
  rgba(192, 197, 211, 0.3) 76%,
  rgba(165, 171, 191, 0.3) 100%
);

// Mixins
@mixin list-span() {
  background-color: $sapphire-10;
  color: $sapphire-130;
  font-weight: bold;
  border-radius: 4px;
  padding: 3px 4px;
  white-space: nowrap;
  font-size: 12px;
  letter-spacing: 0.5px;
}

@mixin line-clamp($numberOfLines: 1) {
  display: -webkit-box;
  -webkit-line-clamp: $numberOfLines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
