@use "../global-variables" as *;

.team-personality-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  > .card {
    padding: 20px;
  }
}
.trait-card {
  border: none;
  border-top: 1px solid #b1b3b3;
  padding: 17px 0;
  position: relative;
  > .notable-trait-icon {
    position: absolute;
    top: 17px;
    right: 0;
  }
  > .trait-card-header {
    display: flex;
    align-items: center;
    gap: 10px;
    > h3 {
      margin: 0;
    }
  }
}

.trait-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.trait-cards-expand-button-container {
  border-top: 1px solid #b1b3b3;
  padding-top: 12px;
  text-align: center;
}
.count-text {
  color: #86888b;
  margin-bottom: 12px;
  display: block;
}

.team-personality-report-card__body,
.team-personality-report-card__header {
  padding: 20px;
}
.team-personality-report-card__header {
  border-bottom: 2px solid $charcoal-20;
}

.team-personality-report-card__body {
  display: grid;
  grid-template-columns: 2fr 4fr;
  gap: 20px;
}

.team-personality-report-card__trait-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.team-personality-report-card__trait {
  border-bottom: 1px solid $charcoal-20;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 20px;
  .what-does-it-mean {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.what-this-means-paragraph {
  font-style: italic;
  padding-top: 10px;
  color: $sapphire-150;
}
