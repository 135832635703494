@use "../global-variables" as *;

p.invite-link {
  overflow: hidden;
  white-space: nowrap;
  margin: 10px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
}

.textarea-label {
  font-size: 14px;
  font-weight: bold;
  color: $charcoal-100;
  &.form-label {
    white-space: wrap;
  }
}

.invitation-sent {
  display: flex;
  flex-direction: column;
  align-items: center;
  > span {
    font-weight: bold;
  }
  > p {
    max-width: 500px;
  }
}

.simple-form-label {
  font-size: 14px;
  font-weight: bold;
  color: $charcoal-100;
}

.disabled-invite-link.card {
  cursor: not-allowed;
  background-color: $charcoal-10;
}
.invite-link.card {
  cursor: pointer;
  color: $sapphire-100;
  border-color: $sapphire-100;
  &:hover {
    color: $sapphire-80;
    border-color: $sapphire-80;
  }
}

.react-datepicker__input-container {
  > input {
    padding: 6px 12px;
    border-radius: 4px;
    border: 1px solid $charcoal-50;
  }
}

div.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0;
}
div.react-datepicker__day--selected {
  background-color: $sapphire-100;
  &:hover {
    background-color: $sapphire-100;
  }
}
.react-datepicker__time-list-item--selected {
  background-color: $sapphire-100 !important;
}

textarea.form-control,
input.form-control,
.basic-form-input.form-input {
  border: 1px solid $charcoal-50;
  &::placeholder {
    color: $charcoal-50;
  }
}

.form-label-dark {
  color: black;
}
.form-label-grey {
  color: $charcoal-100;
}

.form-label-dark,
.form-label-grey {
  font-size: 14px;
  font-weight: bold;
}

input.form-control.empty {
  background-color: $charcoal-05;
}

#react-select-7-placeholder {
  color: $charcoal-70;
  font-size: 14px;
}

.css-qbdosj-Input {
  > div[data-lastpass-icon-root="true"] {
    display: none !important;
  }
}

.simple-select-input {
  div[data-lastpass-icon-root="true"] {
    display: none !important;
  }
}

.select__multi-value {
  background-color: white !important;
  color: $charcoal-100;
  border: 1px solid $charcoal-30;
  &.danger {
    border-color: $indicator-error-red-100;
  }
  &.warning {
    border-color: $indicator-alert-yellow-100;
  }
}

.form-group.text-input {
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  > .max-length {
    white-space: nowrap;
    margin-top: 0;
  }
  &.column {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }
}

.multi-value.select-item {
  background-color: white;
  border: 1px solid $charcoal-30;
  .simple-text {
    font-size: 16px;
    font-weight: 700;
    color: $charcoal-100;
  }
  &.danger {
    border: 1px solid $indicator-error-red-100;
  }
}

.member-option {
  display: flex;
  gap: 8px;
  .member-name {
    display: block;
    font-size: 13px;
    color: $navy-100;
  }
  .member-email {
    font-size: 12px;
    color: $charcoal-100;
  }
}

.invalid-box {
  background-color: white;
  border: 1px solid $charcoal-30;
  border: 1px solid $indicator-error-red-100;
  color: $charcoal-100;
  font-weight: 700;
  padding: 8px 12px;
  border-radius: 4px;
}
