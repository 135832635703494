@use "../global-variables" as *;

button.btn {
  font-weight: 700;
  &.sm {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 12px;
  }
  &.lg {
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
  }
  &.dropdown-toggle {
    font-weight: normal;
  }
}

// Overriding Bootstrap classes to match criteria
button.btn-outline-primary {
  color: $sapphire-100 !important;
  border: 1px solid $sapphire-100;
  &:hover {
    background-color: $sapphire-10;
    border: 1px solid $sapphire-100;
  }
  &:active,
  &.show {
    background-color: $sapphire-20 !important;
    border: 1px solid $sapphire-100;
  }
}
button.btn-outline-primary.white-button {
  background-color: white;
}

button.btn-primary {
  background-color: $sapphire-100;
  &:hover {
    background-color: $sapphire-90;
  }
  &:active,
  &:focus,
  &:focus-visible {
    background-color: $sapphire-130 !important;
  }
  &.disabled,
  &:disabled {
    background-color: $sapphire-30;
    border: none;
  }
}

button.btn-outline-primary.tertiary-blue {
  background-color: $sapphire-05;
  border: none;
}

.dropdown-toggle.btn.btn-light {
  border: 1px $charcoal-50 solid;
  background-color: white;
  text-align: left;
  border: $sapphire-20 1px solid;
}

button.btn.button-with-chevron {
  align-items: center;
  display: flex !important;
  gap: 8px;
}
