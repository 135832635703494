@use "../global-variables" as *;

.guide-pdf-download {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  margin-bottom: 6px;
  color: $navy-100;
}

.pending-test-card.card {
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 5fr 2fr;
}
.title-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > h3 {
    margin-bottom: 0;
  }
}

.strength-and-challenges-title {
  background-color: $navy-100;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 12px 16px;
}

.right-bar-card.card {
  border-left: 4px solid black;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.workplace-insights-more-insights-card {
  display: flex;
  gap: 12px;
  flex-direction: column;
  > h2 {
    margin-bottom: 0;
  }
}
.workplace-insights-more-insights-card__section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

.development-suggestions-title-badge {
  display: flex;
  width: fit-content;
  border-radius: 20px;
  color: white;
  align-items: center;
  padding: 8px;
}

.first-cell,
.middle-cell,
.last-cell {
  width: 100px;
}

.stressor-title {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.43;
  color: #001f4e;
}

table.workplace-challenges-and-motivators-table {
  td:first-of-type {
    padding: 12px;
  }
  tr.dark-row {
    background-color: #f5f6fa;
  }
  td.active {
    color: $cyan-100;
  }
  td.data-cell {
    text-align: center;
    position: relative;
    z-index: 2;
    &::before {
      content: "";
      position: absolute;
      background-color: $charcoal-20; /* Set the color of the horizontal bar */
      border-radius: 1px;
      z-index: -1;
      height: 4px;
      top: 50%;
      left: 4px;
      right: 4px;
    }
  }

  .data-cell.first-cell {
    &::before {
      left: 50%;
    }
  }

  .data-cell.last-cell {
    &::before {
      right: 50%;
    }
  }
}

.not-available-card.card {
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  height: 300px;
  > p {
    max-width: 808px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 16px;
  }
}

.collaboration-guide-header {
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items: center;
}

.collaboration-guide-avatars {
  display: flex;
  justify-self: center;
  > .avatar-circle:not(:last-child) {
    margin-right: -10px;
  }
}

.collaboration-tips {
  > h3 {
    color: black;
    > svg {
      color: $sapphire-100;
    }
  }
  background-color: $sapphire-20;
}

.collaboration-guide.workplace-insights-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  > div {
    padding: 12px 16px;
    font-size: 14px;
  }

  > .collaboration-tips {
    padding: 16px;
    background-color: $sapphire-20;
    > h3 {
      color: black;
      > svg {
        color: $sapphire-100;
      }
    }
  }
}

.manager-guide-card.card {
  > .card-header {
    background-color: $sapphire-150;
    color: white;
    font-size: 16px;
  }
}

.radar-chart-corner-labels {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-right: 40px;
  padding-left: 40px;
  &:first-of-type {
    top: 40px;
  }
  &:last-of-type {
    bottom: 40px;
  }
  > span {
    padding: 4px 12px;
    border-radius: 20px;
    color: white;
    font-size: 14px;
    font-weight: bold;
    &.cyan {
      background-color: $cyan-100;
    }
    &.sapphire {
      background-color: $sapphire-100;
    }
    &.poppy {
      background-color: $poppy-100;
    }
    &.gold {
      background-color: $gold-100;
    }
  }
}

.workplace-insights-report {
  padding: 20px;
  display: flex;
  gap: 24px;
  flex-direction: column;
  h2 {
    margin-bottom: 12px;
  }
}

.workplace-insights-bar-container {
  position: relative;
  > .bar {
    width: 100%;
    height: 10px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    margin: 12px 0;
    > div {
      background-color: $charcoal-20;
    }
  }
  > .members {
    position: absolute;
    bottom: -100%;
    width: 100%;
    > .member {
      position: absolute;
      right: 0;
      bottom: 0;
      width: fit-content;
    }
  }
}

.workplace-insights-bar-labels {
  display: flex;
  justify-content: space-between;
}

.simple-collapsible-card {
  padding: 20px 16px;
  .collapsed-container {
    margin-top: 20px;
  }
}
.simple-collapsible-card__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > h2 {
    margin-bottom: 0;
  }
}

.workplace-insights-strengths {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card {
    padding: 20px;
  }
}

.workplace-insights-strengths__lists {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  li {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

.card-with-left-strip {
  overflow: hidden;
  &::after {
    content: "";
    width: 8px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: $gold-100;
  }
  &.dark-blue::after {
    background-color: $sapphire-150;
  }
  &.blue::after {
    background-color: $sapphire-100;
  }
  &.poppy::after {
    background-color: $poppy-100;
  }
  &.cyan::after {
    background-color: $cyan-100;
  }
  &.ruby::after {
    background-color: $ruby-100;
  }
}

.collaboration-guide-content__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
